import './App.css';
import Footer from './components/footer/footer';
import Hero from "./components/hero";
import Join from './components/join/join';
import Plans from './components/plans/plans';
import Programs from './components/programs/Programs';
import Reasons from './components/Reasons/Reasons';
import Testimonials from './components/testimonials/testimonials';

function App() {
  return (
    <div className="App">
       <Hero/>
       <Programs/>
       <Reasons/>
       <Plans/>
        <Testimonials/>
        <Join/>
        <Footer/>

    </div>
  );
}

export default App;
